:root {
  --main-1st-color: #0E1F2F;
  --main-2nd-color: #FFD700;
  --main-3rd-color: #D8DBE2;
  --main-4th-color: #93032E;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*.bg-dark-cyan {
  background-color: #368F8B;
}

.hover-bg-dark-cyan {
  background-color: #368F8B;
}

.bg-ghost-white {
  background-color: #ECEBF3;
}*/

/*.wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100% ;
    grid-auto-rows: 1fr;
}*/

/* For desktop: */
/*@media only screen and (min-width: 768px) {
  .wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 25% 5% 70% ;
    gap: 4px;
    grid-auto-rows: 1fr;
  }
}*/

/*@media only screen and (max-width: 600px) {
  body {
    background-color: lightblue;
  }
}*/

/*.indicatorLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / 2;
  grid-row: 1 / 4;
}
.separator {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 2 / 3;
  grid-row: 1 / 4;
}
.indicatorDataOne {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}
.indicatorDataTwo {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}
.indicatorDataThree {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 3 / 4;
  grid-row: 3 / 4;
}
*/