.App {
  position: fixed;
  width: 100%;
  text-align: center;
  background-color: #D8DBE2;
  /*Gainsboro*/
  /*background-color: #0e1f2f;*/
  /*Rich Black FOGRA 29*/
  /*background-color: #93032e;*/
  /*Antique Ruby*/
  /*background-color: #ffd700;*/
  /*Gold Web Golden*/
}

.stack {
  display: grid;
  justify-content: space-evenly;
  width: 100%;
  padding: 2%;
  height: 33vh;
  grid-template-columns: repeat(6, minmax(10px, 1fr));
  grid-gap: 1.5%;
}

/* .indicatorBox {
  display: grid;
  background-color: red;
  grid-template-columns: 1fr 3fr;
  align-items: center;
} */

/* .indicatorText {
  display: grid;
  grid-template-rows: 4vh 10vh 4vh;
  border-left: 1px solid;
  border-left-color: '#25283D';
  padding-left: 10%;
  align-content: center;
  align-items: center;
  grid-row-gap: 1vh;
  font-size: 1rem;
} */

.CheckServicesContainer {
  /* position:  fixed; */
  /* width: 100vw; */
  /*width: 100vh;*/
  /* height: 90%; */
  /* height: 100vh; */
  padding: 2%;
  display: grid;
  place-items: center;
  gap: 2%;
  /*grid-gap: 4%;*/
}

.topServicesSection {
  display: flex;
  justify-content: center;
  width: 90%;
  /* height: 46%; */
  /* height: 40vh; */
  /*display: grid;*/
  gap: 2%;
}

.bottomSection {
  /*display: flex;*/
  /* height: 90%; */
  /*height: 45vh;*/
  /*display: grid;*/
  /*gap: 3%;*/
  /* height: 40vh; */
  width: 90%;
  /*background-color: #D8DBE2;*/
}

.topLeftSection {
  /* height: 100%; */
  width: 100%;
  /*  display: grid;
  grid-column: 1 / span 3;*/
  /* padding: 1vw; */
  background-color: #FFFFFF;
  border-radius: 25px
}

.topRightSection {
  /* height: 100%; */
  width: 0%;
  border-radius: 25px;
  /*height: 20vh;*/
  /*flex-grow: 1;*/
  /*grid-column: 4 / span 4;*/
  /* background-color: #D8DBE2; */
  background-color: #FFFFFF;
}

.ServicesForm {
  display: grid;
  height: 65%;
  width: 100%;
  grid-template-columns: 28% 70%;
  grid-column-gap: 2%;
  /*justify-items: center;*/
  /*grid-gap: 2vh;*/
  /*margin: 1%;*/
  /*margin: 1% 0px 1% 0px;*/
}

.ServicesForm>div {
  /* width: 100%; */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  /* align-items: stretch; */

  /*flex-wrap: nowrap;
  align-content: flex-end;
  align-items: stretch;*/

  /*justify-items: end;*/
  /*background-color: blue;*/
}

.ServicesFormIcon {
  /* justify-self: center; */
  /* align-self: center; */
  /* border-style: solid; */
  /* border-width: 1px; */
  /* border-color: black; */
  /*background-color: red;*/
}

@media (min-width: 768px) {
  .topServicesSection {
      width: 100%;
  }
  .bottomSection {
    width: 97%;
    height: 60vh;
  }
  .topLeftSection {
    width: 55%;
  }
  .topRightSection {
    width: 40%;
  }
}

@media only screen and (max-width: 1220px) and (-webkit-min-device-pixel-ratio: 2) {

  /* .stack {
    display: grid;
    justify-content: space-evenly;
    width: 100%;
    height: 10vh;
    grid-template-columns: repeat(6, minmax(1fr, 1fr));
    grid-gap: 1%;
  } */

  /* .indicatorBox {
    display: grid;
    grid-template-columns: 1fr;
  } */

  /* .indicatorText {
    display: none;
  } */

  /* .topLeftSection {
    grid-column: 1 / span 12;
  } */

  /* .topRightSection {
    grid-column: 1 / span 12;
  } */
}